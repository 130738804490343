<template>
    <!-- 编辑企业统计页面 -->
    <el-form :model="AddData" :rules="rules" ref="AddData" size="small" label-width="130px">
        <!-- 头部开始 -->
        <el-row class="pur-top">
        <el-col :span="10"><span class="pur-size">{{ purTitle }}</span></el-col>
        <el-col :span="14" class="pur-right">
            <el-button size="small" @click="AddDatasubmitForm('AddData')">保存</el-button>
            <el-button size="small" style="margin-left: 24px"  @click="Addopen">取消</el-button>
        </el-col>
        </el-row>
        <!-- 头部结束 -->
        <!-- 内容开始 -->
        <div class="pur-nav">
            <!-- 表单 -->
            <div class="pur-table">
                <el-row>
                    <!-- 基础信息 -->
                    <el-col :span="24">
                        <div class="see-top">基础信息</div>
                        <el-row>
                            <el-col :span="2">
                                <span style="color:red;">* </span>
                                <label style="color:#666;font-size:14px;line-height:30px;">企业名称</label>
                            </el-col>
                            <el-form-item prop="enterpriseName">
                                <el-col :span="7">
                                    <el-input v-model="AddData.enterpriseName" placeholder="请输入企业名称" @input="changesize"></el-input>
                                    <div class="bormp" v-if="listblon">
                                        <div class="bpmslist" v-for="(v,k) in listcm" :key="k" @click="bpmclick(v.Name)">{{v.Name}}</div>
                                    </div>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2" >
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">注册时间</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-date-picker
                                    disabled
                                    v-model="AddData.regTime"
                                    type="date"
                                    placeholder="请选择注册时间"
                                    value-format="yyyy-MM-dd"
                                    style="width:100%;">
                                    </el-date-picker>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2">
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">税号</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-input disabled v-model="AddData.rateNumber" placeholder="请输入税号"></el-input>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2">
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">法人姓名</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-input disabled v-model="AddData.legalPerson" placeholder="请输入法人姓名"></el-input>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2">
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">注册资本</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-input disabled v-model="AddData.regMoney" placeholder="请输入注册资本"></el-input>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2">
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">实缴资本</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-input disabled v-model="AddData.recCap" placeholder="请输入实缴资本"></el-input>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2">
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">企业类型</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-input disabled v-model="AddData.econKind" placeholder="请输入企业类型"></el-input>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2">
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">更新日期</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-input disabled v-model="AddData.updatedDate" placeholder="请输入更新日期"></el-input>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2">
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">注册地址</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-input disabled v-model="AddData.address" placeholder="请输入注册地址"></el-input>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2">
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">经营范围</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-input disabled type="textarea" v-model="AddData.scope" :rows="4"></el-input>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2">
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">省</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-select @change="enterprises($event)" v-model="AddData.provinceCode" placeholder="请选择省" style="width:100%;">
                                        <el-option
                                        v-for="item in provinceOptions"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code">
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2">
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">市</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7"> 
                                    <el-select v-model="AddData.cityCode" placeholder="请选择市" style="width:100%;" @change="citys($event)">
                                        <el-option
                                        v-for="item in cityOptions"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.code">
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2">
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">县 / 区</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7"> 
                                    <el-select v-model="AddData.areaCode" placeholder="请选择县 / 区" style="width:100%;" @change="countys($event)">
                                        <el-option
                                        v-for="item in countyOptions"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.code">
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2">
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">乡</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-select v-model="AddData.countyCode" placeholder="请选择乡" style="width:100%;" @change="villages($event)">
                                        <el-option
                                        v-for="item in villageOptions"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.code">
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2" >
                                <!-- <span style="color:red;">* </span> -->
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">法人身份证</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-input v-model="AddData.legIdentity" placeholder="请输入法人身份证号码"></el-input>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2" >
                                <!-- <span style="color:red;">* </span> -->
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">上户时间</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7" >
                                    <el-date-picker
                                    v-model="AddData.shTime"
                                    type="month"
                                    placeholder="请选择上户时间"
                                    value-format="yyyy-MM"
                                    style="width:100%;">
                                    </el-date-picker>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2">
                                <!-- <span style="color:red;">* </span> -->
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">联系电话</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-input v-model="AddData.phone" placeholder="请输入联系电话"></el-input>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2">
                                <!-- <span style="color:red;">* </span> -->
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">业务员</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-input v-model="AddData.businesser" placeholder="请输入业务员姓名"></el-input>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2">
                                <!-- <span style="color:red;">* </span> -->
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">企业类别</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-select v-model="AddData.type" placeholder="请选择企业类别" style="width:100%;">
                                        <el-option
                                        v-for="item in typeOptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2">
                                <!-- <span style="color:red;">* </span> -->
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">网络货运</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-radio v-model="AddData.isNetwork" :label="true">是</el-radio>
                                    <el-radio v-model="AddData.isNetwork" :label="false">否</el-radio>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2">
                                <!-- <span style="color:red;">* </span> -->
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">小程序轮播标志</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-radio v-model="AddData.rotationFlag" :label="true">是</el-radio>
                                    <el-radio v-model="AddData.rotationFlag" :label="false">否</el-radio>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2.5" >
                                <!-- <span style="color:red;">* </span> -->
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">对公流程配置</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-select v-model="AddData.pubFlowId" style="width:100%;">
                                        <el-option
                                        v-for="item in AddData.pubFlows"
                                        :key="item.id"
                                        :label="item.planName"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2.5" >
                                <!-- <span style="color:red;">* </span> -->
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">对私流程配置</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-select v-model="AddData.perFlowId" style="width:100%;">
                                        <el-option
                                        v-for="item in AddData.perFlows"
                                        :key="item.id"
                                        :label="item.planName"
                                        :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2">
                                <!-- <span style="color:red;">* </span> -->
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">签章免费份数</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-input v-model="AddData.signFreeCount" placeholder="请输入签章免费份数"></el-input>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-col :span="2">
                                <!-- <span style="color:red;">* </span> -->
                                <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">签章收费份数</label>
                            </el-col>
                            <el-form-item>
                                <el-col :span="7">
                                    <el-input v-model="AddData.signChargeCount" placeholder="请输入签章收费份数"></el-input>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <template v-if="AddData.isAuthentication == true">
                            <div class="see-top">资金监管</div>
                            <el-row>
                                <el-col :span="3">
                                    <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">选择供应商</label>
                                </el-col>
                                <el-form-item>
                                    <el-col :span="7">
                                        <el-select v-model="supplierList" multiple placeholder="请选择">
                                            <el-option
                                            v-for="item in entOptions"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                </el-form-item>
                            </el-row>
                            <el-row>
                                <el-col :span="3">
                                    <!-- <span style="color:red;">* </span> -->
                                    <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">平台商户编码（卖家）</label>
                                </el-col>
                                <el-form-item>
                                    <el-col :span="7">
                                        <el-input v-model="AddData.tlMchtNo" placeholder="请输入平台商户编码"></el-input>
                                    </el-col>
                                </el-form-item>
                            </el-row>
                            <el-row>
                                <el-col :span="3">
                                    <!-- <span style="color:red;">* </span> -->
                                    <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">通联会员号</label>
                                </el-col>
                                <el-form-item>
                                    <el-col :span="7">
                                        <el-input v-model="AddData.tlSignNum" placeholder="请输入通联会员号"></el-input>
                                    </el-col>
                                </el-form-item>
                            </el-row>
                            <el-row>
                                <el-col :span="3">
                                    <!-- <span style="color:red;">* </span> -->
                                    <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">交易子账号</label>
                                </el-col>
                                <el-form-item>
                                    <el-col :span="7">
                                        <el-input v-model="AddData.tlAcctSubNo" placeholder="请输入交易子账号"></el-input>
                                    </el-col>
                                </el-form-item>
                            </el-row>
                            <el-row>
                                <el-col :span="3">
                                    <!-- <span style="color:red;">* </span> -->
                                    <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">通联会员开通日期</label>
                                </el-col>
                                <el-form-item>
                                    <el-col :span="7">
                                        <el-date-picker
                                        v-model="AddData.tlOpenTime"
                                        type="date"
                                        placeholder="请选择通联会员开通日期"
                                        value-format="yyyy-MM-dd"
                                        style="width:100%;">
                                        </el-date-picker>
                                    </el-col>
                                </el-form-item>
                            </el-row>
                            <el-row>
                                <el-col :span="3">
                                    <!-- <span style="color:red;">* </span> -->
                                    <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">银行编码</label>
                                </el-col>
                                <el-form-item>
                                    <el-col :span="7">
                                        <el-input v-model="AddData.bnkId" placeholder="请输入银行编码"></el-input>
                                    </el-col>
                                </el-form-item>
                            </el-row>
                            <el-row>
                                <el-col :span="3">
                                    <!-- <span style="color:red;">* </span> -->
                                    <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">对公账号</label>
                                </el-col>
                                <el-form-item>
                                    <el-col :span="7">
                                        <el-input v-model="AddData.acctNum" placeholder="请输入对公账号"></el-input>
                                    </el-col>
                                </el-form-item>
                            </el-row>
                            <el-row>
                                <el-col :span="3">
                                    <!-- <span style="color:red;">* </span> -->
                                    <label style="color:#666;font-size:14px;line-height:30px;margin-left:12px;">联行号</label>
                                </el-col>
                                <el-form-item>
                                    <el-col :span="7">
                                        <el-input v-model="AddData.cnapsCode" placeholder="请输入联行号"></el-input>
                                    </el-col>
                                </el-form-item>
                            </el-row>
                        </template>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!-- 内容结束 -->
    </el-form>
</template>
<script>
export default {
    data() {
        return {
            /* 
            编辑销售收款单数据
            */
            typeOptions:[
                {
                    id:"1",
                    name:'一般纳税人'
                },
                {
                    id:"2",
                    name:'小规模纳税人'
                },
            ],
            purTitle: "",                           // 标题
            // 表单验证
            rules: {
                signFreeCount: [
                    { required: true, message: '请输入签章免费份数', trigger: 'blur' }
                ],
                signChargeCount: [
                    { required: true, message: '请输入签章收费份数', trigger: 'blur' }
                ],
                regTime: [
                    { required: true, message: '请输入注册时间', trigger: 'change' }
                ],
                legIdentity: [
                    { required: true, message: '请输入法人身份证号', trigger: 'blur' },
                    { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确的身份证号', trigger: 'blur'},
                ],
                enterpriseName: [
                    { required: true, message: '请输入企业名称', trigger: 'blur' }
                ],
                shTime: [
                    { required: true, message: '请输入上户时间', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请输入联系电话', trigger: 'blur' },
                    { pattern: /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/, message: '请输入正确的号码格式', trigger: 'blur'}
                ],
                regMoney: [
                    { required: true, message: '请输入注册资本', trigger: 'blur' }
                ],
                businesser: [
                    { required: true, message: '请输入业务员姓名', trigger: 'blur' },
                    { pattern: /^[\u4E00-\u9FA5]{2,4}$/, message: '姓名必须2-4位中文', trigger: 'blur'}
                ],
                legalPerson: [
                    { required: true, message: '请输入法人姓名', trigger: 'blur' },
                    { pattern: /^[\u4E00-\u9FA5]{2,4}$/, message: '姓名必须2-4位中文', trigger: 'blur'}
                ],
                rateNumber:[
                    { required: true, message: '请输入税号', trigger: 'blur' },
                    { pattern: /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/, message: '请输入正确的企业税号', trigger: 'blur'}
                ],
                isNetwork: [
                    { required: true, message: '请选择是否为网络货运', trigger: 'blur' }
                ],
                rotationFlag: [
                    { required: true, message: '请选择是否为小程序轮播标志', trigger: 'blur' }
                ],
            },
            // 行政区划
            provinceOptions:[],             // 省
            cityOptions: [],                // 市
            countyOptions: [],              // 县 区
            villageOptions: [],             // 乡
            // 编辑表单数据
            AddData: {
                carriageNameone:[],
                carriageNametwo:[],
                bnkId:'',
            },
            
            // 企业认证
            listcm:[],   // 企业列表
            listblon:false,
            enterprise:{},  // 企业信息
            supplierList:[],  // 供应商
            entOptions: [],
        };
    },
    created() {
    },
    mounted () {
        this.getpurcon()
        // 接收主页单条数据
        this.AddData = JSON.parse(sessionStorage.getItem('queryAll'));
        // 模块标题
         this.purTitle = this.AddData.enterpriseName;
        this.api.areaInfo.all({parentCode:0})
        .then(res=>{
            if(res.data.code == 200){
                this.provinceOptions = res.data.data;
            }
        })
        if(this.AddData.cityCode != null){
            // 市
            this.api.areaInfo.all({parentCode:this.AddData.provinceCode})
            .then(res=>{
                if(res.data.code == 200){
                    this.cityOptions = res.data.data;
                }
            })
            // 县 区
            this.api.areaInfo.all({parentCode:this.AddData.cityCode})
            .then(res=>{
                if(res.data.code == 200){
                    this.countyOptions = res.data.data;
                }
            })
            // 乡
            this.api.areaInfo.all({parentCode:this.AddData.areaCode})
            .then(res=>{
                if(res.data.code == 200){
                    this.villageOptions = res.data.data;
                }
            })
        }
    },
    methods: {
        // 查询企业
        changesize(){
            // console.log(this.AddData.enterpriseName)
            if(this.AddData.enterpriseName == ""){
                this.enterprise == {};
                this.listblon = false;
            }
            this.api.enterData.seletcname({etcName:this.AddData.enterpriseName})
            .then(res=>{
                if(res.data.code == 200 && res.data.data.length>0){
                    this.listcm = res.data.data;
                    this.listblon = true;
                }
            })
        },
        // 选中企业
        bpmclick(name){
            this.AddData.enterpriseName = name;
            this.listblon = false;
            this.api.enterData.seletcinfo({enterpriseName:name,isAutoAdd:false})
            .then(res=>{
                if(res.data.code == 200){
                    // console.log(res)
                    this.AddData.regTime = res.data.data.regTime;
                    this.AddData.rateNumber = res.data.data.creditCode;
                    this.AddData.legalPerson = res.data.data.legalPerson;
                    this.AddData.regMoney = res.data.data.regMoney;
                    this.AddData.recCap = res.data.data.recCap;
                    this.AddData.econKind = res.data.data.econKind;
                    this.AddData.updatedDate = res.data.data.updatedDate;
                    this.AddData.address = res.data.data.address;
                    this.AddData.scope = res.data.data.scope;
                    this.AddData.imageUrl = res.data.data.imageUrl;

                    if(res.data.data.provinceCode){
                        this.AddData.provinceCode = res.data.data.provinceCode;  // 省
                        this.enterprises(this.AddData.provinceCode); 

                        // if(res.data.data.cityCode){
                        //     this.AddData.cityCode = res.data.data.cityCode;          // 市
                        //     this.citys(this.AddData.cityCode);
                        //     if(res.data.data.areaCode){
                        //         this.AddData.areaCode = res.data.data.areaCode;          // 县/区
                        //         this.countys(this.AddData.areaCode);
                        //         if(res.data.data.countyCode){
                        //             this.AddData.countyCode = res.data.data.countyCode;      // 乡 
                        //             this.villages(this.AddData.countyCode);
                        //         }
                        //     }
                        // }
                    } 
                    
                }
            })
        },
        // 编辑销售收款单表单提交
        AddDatasubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let supplierSel = []
                    this.supplierList.forEach(item => {
                        let obj = {}
                        obj.buyCompanyId = this.AddData.id
                        obj.saleCompanyId = this.entOptions[item].id
                        obj.buyEctName = this.AddData.enterpriseName 
                        obj.saleEctName = this.entOptions[item].label
                        supplierSel.push(obj)
                    })
                    this.AddData.saleEtcs = supplierSel
                    this.AddData.tlzfConfig = {
                        companyId:this.AddData.id,
                        bnkId:this.AddData.bnkId,
                        acctNum:this.AddData.acctNum,
                        cnapsCode:this.AddData.cnapsCode,
                        id:this.AddData.tlid,
                    };
                    this.api.enterData.updateById(this.AddData)
                    .then(res=>{
                        if(res.data.code == 200){
                            var that = this
                            that.$message({
                                type: "success",
                                message: "保存成功!",
                                duration:500,  
                                onClose(){
                                    sessionStorage.removeItem('queryAll');
                                    that.$router.push({name:"Qycensus"})
                                }
                            });
                        }
                    })
                }
            });
        },
        // 取消保存表单
        Addopen() {
            this.supplierList = []
            sessionStorage.removeItem('queryAll');
            this.$router.push({name:"Qycensus"})
        },
        // 选择省
        enterprises(id){
            let resultArr = this.provinceOptions.filter((item)=>{
                return item.code == id;
            });
            this.AddData.proName = resultArr[0].name;
            // console.log(this.AddData.proName)
            this.api.areaInfo.all({parentCode:id})
            .then(res=>{
                if(res.data.code == 200){
                    this.AddData.cityCode = '';                 // 市
                    this.AddData.areaCode = '';                 // 县 区
                    this.AddData.countyCode = '';               // 乡
                    this.cityOptions = res.data.data;
                }
            })
        },
        // 选择市
        citys(id){
            let resultArr = this.cityOptions.filter((item)=>{
                return item.code == id;
            });
            this.AddData.cityName = resultArr[0].name;
            // console.log(this.AddData.cityName)
            this.api.areaInfo.all({parentCode:id})
            .then(res=>{
                if(res.data.code == 200){
                    this.AddData.areaCode = [];                 // 县 区
                    this.AddData.countyCode = '';               // 乡
                    this.countyOptions = res.data.data;
                }
            })
        },
        // 选择县 区
        countys(id){
            let resultArr = this.countyOptions.filter((item)=>{
                return item.code == id;
            });
            this.AddData.areaName = resultArr[0].name;
            this.api.areaInfo.all({parentCode:id})
            .then(res=>{
                if(res.data.code == 200){
                    this.AddData.countyCode = '';               // 乡
                    this.villageOptions = res.data.data;
                }
            })
        },
        // 选择乡
        villages(id){
            let resultArr = this.villageOptions.filter((item)=>{
                return item.code == id;
            });
            this.AddData.countryName = resultArr[0].name;
        },
        // 获取企业列表
        getpurcon(){
            // 传参
            var listByPageData = {
                isAuthentication:true,
                searchStr:'',
                page:{
                    current:1,
                    size:100
                }
            }
            // 渲染表格
            this.api.enterData.all(listByPageData)
            .then(res=>{
                if(res.data.code == 200){
                    let tempList = []
                    res.data.data.records.forEach((el,i) => {
                        tempList.push({
                            value:i,
                            label:el.enterpriseName,
                            id:el.id
                        })
                    });
                    this.entOptions = tempList
                    this.AddData.saleEtcs.forEach((item) => {
                        this.entOptions.forEach((el,index) => {
                            if(item.saleCompanyId == el.id){
                                this.supplierList.push(index)
                            }
                        })
                    })
                }
            })
        },
    }
};
</script>
<style lang="less" scoped>
	.bormp{
		width: 100%;
		min-height: 36px;
		border:1px solid #e4e4e4;
		border-top:0;
		padding: 6px 5px;
		box-sizing: border-box;
		.bpmslist{
			font-size: 14px;
			line-height: 24px;
			color: #333;
			box-sizing: border-box;
            cursor: pointer;
		}
	}
// 头部开始
.pur-top {
    width: 100%;
    height: 50px;
    padding: 9px 24px 9px 32px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
    .pur-size {
        font-size: 16px;
        line-height: 32px;
        color: #333;
        font-weight: 600;
    }
    .pur-right {
        .el-button {
            margin-left: 16px;
            float: right;
        }
    }
}
// 头部结束
// 表单内容开始
.smbox{
    width:100%;
    min-height:78px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    padding:8px;
    box-sizing: border-box;
}
.details{
   width:100%;
   height:32px;
   border: 1px solid #DCDFE6;
   border-radius: 4px;
   .details-nav{
       background-color:#F5F7FA;
        width: calc(100% - 73px);
        height: 32px;
        float: left;
        overflow: hidden;
        .details-tag{
            width: 44%;
            height: 32px;
            display: inline-block;
            .tag-box{
                width: 100%;
                display:inline-block;
                height: 32px;
                color: #C4C4C4;
                .el-tag{
                    color:#333;
                    width: 80%;
                    overflow: hidden;           // 盒子溢出隐藏
                    text-overflow:ellipsis;     // 文字溢出显示省略号
                    white-space: nowrap;        // 文字不换行
                }
                b{
                    width: 20%;
                    display: inline-block;
                }
            }
        }
        .details-conent{
            width:56%;
            text-align: right;
            height: 32px;
            float: right;
            padding-right: 4px;
            box-sizing: border-box;
            overflow: hidden;           // 盒子溢出隐藏
            text-overflow:ellipsis;     // 文字溢出显示省略号
            white-space: nowrap;        // 文字不换行
        }
   }  
   .details-size{
       background-color: #F5F7FA;
        border-left: 1px solid #DCDFE6;
        height:100%;
        text-align: center;
        width:73px;
        box-sizing: border-box;
        color:#333;
        cursor: pointer;
        float: right;
        span{
            width: 100%;
            height: 32px;
            font-size: 13px;
        }
   }
}
.pur-nav {
    height: calc(100vh - 110px);
    background-color: #fff;
    margin: 0 60px;
    box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
    padding:33px 0 33px 33px;
    box-sizing: border-box;
    .pur-table {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        .see-top {
            height: 40px;
            font-weight: 600;
            font-size: 14px;
            line-height: 45px;
            border-bottom: 1px dashed #999999;
            margin-bottom:24px;
        }

        .el-select--small{
                width: 100% !important;
            }
        .see-lable {
            padding: 15px 0;
            .el-input{
                .input{
                    width: 430px;
                }
            }
        }
    }
}
.AddPurIcon{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #828282;
    color: #828282;
    font-size:12px;
    text-align: center;
    line-height: 16px;
    margin-top: 14px;
    margin-left: -25px;
}
.AddbuttonUpload{
    width: 100px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    font-size: 14px;
    color: #666;
    line-height: 32px;
    text-align: center;
}
.AddbuttonUpload:hover{
    background: #e4e4e4;
    color: #333;
}
// 表单内容结束
</style>